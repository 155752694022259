import logo from './logo.svg';
import './App.css';
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";


function App() {
  return (
    <div className="App">
      <Analytics/>
      <SpeedInsights/>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Edit <code>src/App.js</code> and save to reload.</p>
        <p>Hello World...</p>
        <a className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >Learn React
        </a>
      </header>
      <div>
        <h1>STOP WASTING YOUR TIME</h1>
      </div>
    </div>
  );
}

export default App;
